import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { ref, getDownloadURL } from "firebase/storage";
import { db, storage } from "./config/firebaseConfig";

const Play = () => {
    const [audioURL, setAudioURL] = useState("");
    const [imageURL, setImageURL] = useState("");
    const searchParams = useSearchParams();
    const audioId = searchParams.get("audioId");

    useEffect(() => {
        console.log('Play component loaded');

        if (audioId) {
            const audioRef = ref(storage, `audios/${audioId}.webm`);
            const imageRef = ref(storage, `images/${audioId}.jpg`);

            getDownloadURL(audioRef).then((url) => {
                setAudioURL(url);
            });

            /*getDownloadURL(imageRef).then((url) => {
              setImageURL(url);
            });*/
        }
    }, [audioId]);

    return (
        <div className="flex items-center justify-center h-screen w-full bg-gray-100">
            <div className="w-full text-center">
                <div className="w-1/2 m-auto rounded-2xl border p-4 bg-white shadow-lg">
                    {imageURL && (
                        <div className="border rounded-md p-2 h-full bg-gray-50">
                            <img src={imageURL} alt="Associated image" className="w-full" />
                        </div>
                    )}
                    {audioURL && (
                        <div className="border rounded-md p-2 h-full mt-4 bg-gray-50">
                            <audio controls src={audioURL} className="w-full" />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Play;
