import React, { useEffect, useState, useRef } from "react";
import WaveSurfer from "wavesurfer.js";
import QRCode from "qrcode";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { v4 as uuidv4 } from 'uuid';
import { db, storage } from "./config/firebaseConfig";
import { doc, setDoc } from "firebase/firestore";

export default function MicrophoneComponent() {
    const [isRecording, setIsRecording] = useState(false);
    const [recordingComplete, setRecordingComplete] = useState(false);
    const [audioURL, setAudioURL] = useState("");
    const [qrCodeURL, setQRCodeURL] = useState("");
    const mediaRecorderRef = useRef(null);
    const audioChunksRef = useRef([]);
    const waveSurferRef = useRef(null);
    const waveformRef = useRef(null);
    const [selectedFrameStart, setSelectedFrameStart] = useState(0);
    const [selectedFrameEnd, setSelectedFrameEnd] = useState(0);
    const [audioDuration, setAudioDuration] = useState(0);

    useEffect(() => {
        console.log("MicrophoneComponent mounted");

        if (waveformRef.current) {
            waveSurferRef.current = WaveSurfer.create({
                container: waveformRef.current,
                waveColor: "violet",
                progressColor: "purple",
                backend: "MediaElement",
                plugins: [
                    WaveSurfer.timeline.create({
                        container: "#wave-timeline",
                    }),
                ],
            });
        }

        return () => {
            if (waveSurferRef.current) {
                waveSurferRef.current.destroy();
            }
        };
    }, []);

    useEffect(() => {
        console.log("MicrophoneComponent mounted");

        if (recordingComplete && audioURL && waveformRef.current) {
            waveSurferRef.current = WaveSurfer.create({
                container: waveformRef.current,
                waveColor: "black",
                progressColor: "white",
                cursorColor: "white",
                barWidth: 3,
                barHeight: 1,
                barGap: 2,
            });

            waveSurferRef.current.load(audioURL);
        }

        return () => {
            if (waveSurferRef.current) {
                waveSurferRef.current.destroy();
            }
        };
    }, [recordingComplete, audioURL]);

    const startRecording = () => {
        navigator.mediaDevices.getUserMedia({ audio: true }).then((stream) => {
            const mediaRecorder = new MediaRecorder(stream);
            mediaRecorderRef.current = mediaRecorder;

            mediaRecorder.start();
            setIsRecording(true);

            mediaRecorder.ondataavailable = (event) => {
                audioChunksRef.current.push(event.data);
            };

            mediaRecorder.onstop = async () => {
                const audioBlob = new Blob(audioChunksRef.current, { type: "audio/webm" });
                const audioURL = URL.createObjectURL(audioBlob);
                setAudioURL(audioURL);
                audioChunksRef.current = [];
                setRecordingComplete(true);

                if (waveSurferRef.current) {
                    waveSurferRef.current.load(audioURL);
                    waveSurferRef.current.on("ready", () => {
                        setAudioDuration(waveSurferRef.current.getDuration());
                    });
                }
            };
        });
    };

    const stopRecording = () => {
        if (mediaRecorderRef.current) {
            mediaRecorderRef.current.stop();
            setIsRecording(false);
        }
    };

    const handleToggleRecording = () => {
        if (!isRecording) {
            startRecording();
        } else {
            stopRecording();
        }
    };

    const uploadAudioAndGenerateQRCode = async (audioBlob) => {
        try {
            const audioId = uuidv4(); // Generate a secure, unique ID
            const audioRef = ref(storage, `audios/${audioId}.webm`);
            const snapshot = await uploadBytes(audioRef, audioBlob);
            console.log("Uploaded audio file!", snapshot);

            const audioURL = await getDownloadURL(audioRef);
            console.log("Audio file available at", audioURL);

            const clientData = {
                audioURL,
                // Add other client-related data here
            };

            await setDoc(doc(db, "clients", audioId), clientData);

            const appUrl = `http://192.168.1.128:3000/play?audioId=${audioId}`;
            const qrCodeDataURL = await QRCode.toDataURL(appUrl);
            setQRCodeURL(qrCodeDataURL);
            console.log("QR Code generated", qrCodeDataURL);
        } catch (error) {
            console.error("Error uploading audio, image or generating QR code:", error);
        }
    };

    const handleSave = async () => {
        if (audioURL) {
            const audioBlob = await fetch(audioURL).then((res) => res.blob());
            await uploadAudioAndGenerateQRCode(audioBlob);
        }
    };

    const handleFileDrop = async (event) => {
        const file = event.target.files[0];
        if (file) {
            try {
                const audioBlob = new Blob([file], { type: file.type });
                const audioURL = URL.createObjectURL(audioBlob);
                setAudioURL(audioURL);
                setRecordingComplete(true);

                if (waveSurferRef.current) {
                    waveSurferRef.current.load(audioURL);
                    waveSurferRef.current.on("ready", () => {
                        setAudioDuration(waveSurferRef.current.getDuration());
                    });
                }
            } catch (error) {
                console.error("Error handling dropped file:", error);
            }
        }
    };

    return (
        <div className="flex items-center justify-center h-screen w-full bg-gradient-to-r from-pink-300 via-purple-300 to-blue-300">
            <div className="w-full text-center">
                <div className="w-1/2 m-auto rounded-2xl border p-4 bg-white shadow-lg">
                    {recordingComplete && (
                        <>
                            <div className="flex-1 flex w-full justify-between items-center">
                                <div className="space-y-1 text-center">
                                    <p className="text-lg font-bold">
                                        <span className="bg-gradient-to-r from-purple-400 to-pink-400 text-transparent bg-clip-text">Recorded</span>
                                    </p>
                                </div>
                            </div>
                            <div className="relative" ref={waveformRef} id="waveform" style={{ marginBottom: '1rem' }}></div>
                            {audioURL && (
                                <div className="border rounded-md p-2 h-full bg-pink-50">
                                    <audio controls src={audioURL} className="w-full" />
                                </div>
                            )}
                            {audioDuration > 0 && (
                                <div className="border rounded-md p-2 h-full mt-4 bg-pink-50">
                                    <p>Select a frame:</p>
                                    <input
                                        type="range"
                                        min={0}
                                        max={audioDuration}
                                        value={selectedFrameStart}
                                        onChange={(e) => setSelectedFrameStart(Number(e.target.value))}
                                    />
                                    <input
                                        type="range"
                                        min={selectedFrameStart}
                                        max={audioDuration}
                                        value={selectedFrameEnd}
                                        onChange={(e) => setSelectedFrameEnd(Number(e.target.value))}
                                    />
                                </div>
                            )}
                            <button
                                onClick={handleSave}
                                className="mt-4 bg-green-400 hover:bg-green-500 text-white py-2 px-4 rounded-full focus:outline-none shadow-lg"
                            >
                                Guardar
                            </button>
                        </>
                    )}

                    {qrCodeURL && (
                        <div className="border rounded-md p-2 h-full bg-pink-50 mt-4">
                            <img src={qrCodeURL} alt="QR Code to play audio" className="m-auto" />
                            <p className="mt-2 text-sm">Escanea para reproducir el audio</p>
                        </div>
                    )}

                    <div className="flex items-center w-full">
                        {isRecording ? (
                            <button
                                onClick={handleToggleRecording}
                                className="mt-10 m-auto flex items-center justify-center bg-red-400 hover:bg-red-500 rounded-full w-20 h-20 focus:outline-none shadow-lg transform transition-transform duration-300 hover:scale-105"
                            >
                                <svg
                                    className="h-12 w-12"
                                    viewBox="0 0 24 24"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path fill="white" d="M6 19h4V5H6v14zm8-14v14h4V5h-4z" />
                                </svg>
                            </button>
                        ) : (
                            <button
                                onClick={handleToggleRecording}
                                className="mt-10 m-auto flex items-center justify-center bg-blue-400 hover:bg-blue-500 rounded-full w-20 h-20 focus:outline-none shadow-lg transform transition-transform duration-300 hover:scale-105"
                            >
                                <svg
                                    viewBox="0 0 256 256"
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="w-12 h-12 text-white"
                                >
                                    <path
                                        fill="currentColor"
                                        d="M128 176a48.05 48.05 0 0 0 48-48V64a48 48 0 0 0-96 0v64a48.05 48.05 0 0 0 48 48ZM96 64a32 32 0 0 1 64 0v64a32 32 0 0 1-64 0Zm40 143.6V232a8 8 0 0 1-16 0v-24.4A80.11 80.11 0 0 1 48 128a8 8 0 0 1 16 0a64 64 0 0 0 128 0a8 8 0 0 1 16 0a80.11 80.11 0 0 1-72 79.6Z"
                                    />
                                </svg>
                            </button>
                        )}
                    </div>
                    <div className="mt-6 text-center">
                        <label
                            htmlFor="file-upload"
                            className="block w-full bg-blue-400 text-white py-2 px-4 rounded-xl cursor-pointer hover:bg-blue-500"
                        >
                            Pulsa o arrastra el fichero mp3
                        </label>
                        <input
                            id="file-upload"
                            type="file"
                            className="hidden"
                            accept="audio/mp3"
                            onChange={handleFileDrop}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}
