// src/index.js
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import MicrophoneComponent from "./MicrophoneComponent";
import Play from "./Play";
import './globals.css'; // Importa Tailwind CSS

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <Routes>
        <Route path="/play" element={<Play />} />
        <Route path="/" element={<MicrophoneComponent />} />
      </Routes>
    </Router>
  </React.StrictMode>,
  document.getElementById("root")
);
