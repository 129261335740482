import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

// Tu configuración de Firebase aquí
const firebaseConfig = {
    apiKey: "AIzaSyDQ2bpZPHFGA0KSQW0bvkqU999g18SY7Zw",
    authDomain: "soundingmemories.firebaseapp.com",
    projectId: "soundingmemories",
    storageBucket: "soundingmemories.appspot.com",
    messagingSenderId: "1043211840299",
    appId: "1:1043211840299:web:3aec927bec93b85952b36e",
    measurementId: "G-84B2NZ1G2K"
};

// Inicializa Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const storage = getStorage(app);

export { db, storage };
